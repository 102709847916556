<template>
  <v-container class="my-16">
    <v-row justify="center">
      <v-col md="10">
        <v-card color="#55a220" rounded="lg">
          <v-card-title>
            <v-avatar color="success" class="mr-3">
              <v-icon large>
                {{ icons.mdiCheckBold }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-h6 ma-0 pa-0" style="line-height: 1">Sipariş Bilgi</h3>
              <small class="text-caption"> Siparişiniz başarılı bir şekilde tarafımıza iletilmiştir </small>
            </div>
          </v-card-title>

          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="3">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>
                        {{ icons.mdiQrcodeScan }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Sipariş Numarası</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ detail.head.id }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>
                        {{ icons.mdiCalendar }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Sipariş Tarihi</v-list-item-subtitle>
                      <v-list-item-title>
                        {{ detail.head.siparis_tarihi }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>
                        {{ icons.mdiContactlessPayment }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Ödeme</v-list-item-subtitle>
                      <v-list-item-title v-if="detail.head.odeme_tipi === '1'" class="mb-2">
                        Kredi Kartı
                      </v-list-item-title>
                      <v-list-item-title v-if="detail.head.odeme_tipi === '2'" class="mb-2">
                        Banka Havalesi
                      </v-list-item-title>
                      <v-list-item-title v-if="detail.head.odeme_tipi === '2'"> </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="3">
                <v-list color="#398604" rounded dense class="px-0" style="height: 100%">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon>
                        {{ icons.mdiTruckFast }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-subtitle>Teslim Tarihi</v-list-item-subtitle>
                      <v-list-item-title class="mb-2">
                        {{ detail.head.teslim_tarihi.slice(0, 10) }}
                      </v-list-item-title>
                      <v-list-item-title>
                        <v-chip label x-small color="white">
                          <span class="black--text">
                            {{ detail.head.kargo_bilgisi.kargo_adi }}
                          </span>
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col md="10">
        <v-card color="grey lighten-1" rounded="lg">
          <v-card-title>
            <v-avatar color="night" class="mr-3">
              <v-icon :size="24">
                {{ icons.mdiFormatListText }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-h6 ma-0 pa-0" style="line-height: 1">Sipariş Listesi</h3>
              <small class="text-caption"> Sipariş ettiğiniz ürünlerin listesi </small>
            </div>
          </v-card-title>
          <v-card-text>
            <v-list color="transparent" dense class="pa-0">
              <div v-for="(line, index) in detail.lines" :key="`LIST-${index}`">
                <v-hover v-slot="{ hover }">
                  <v-list-item
                    :three-line="!$vuetify.breakpoint.mdAndUp"
                    :two-line="$vuetify.breakpoint.mdAndUp"
                    :class="hover ? 'night' : null"
                    class="py-2 py-md-0 px-0 px-md-2"
                  >
                    <v-list-item-avatar class="mx-0 mx-md-3" :size="$vuetify.breakpoint.mdAndUp ? 75 : 50">
                      <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 75 : 50" tile>
                        <v-img
                          :max-height="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                          :max-width="$vuetify.breakpoint.mdAndUp ? 60 : 45"
                          :src="
                            line.resim
                              ? $baseUrlAPI + '' + line.resim
                              : require('@/assets/images/misc/not-image.png')
                          "
                        ></v-img>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-action>
                      <v-sheet :max-width="$vuetify.breakpoint.mdAndUp ? 120 : 80" color="transparent">
                        <CounterTo
                          :count="false"
                          :uid="line.uid"
                          :plant-id="line.selected_plant"
                          :miktar="line.qty"
                          :birim="line.birim"
                          :birim-ritim="line.birim_ritmi"
                          bg-color="counter"
                          button-color="black"
                          :light="true"
                          @refreshBasket="refreshBasket()"
                        />
                      </v-sheet>
                    </v-list-item-action>
                    <v-list-item-content :class="$vuetify.breakpoint.mdAndUp ? 'ml-6' : 'ml-2'">
                      <div>
                        <v-chip x-small label :color="line.plant == '01' ? 'secondary' : 'warning'">
                          {{ line.plant == '01' ? 'Tekirdağ' : 'Konya' }}
                        </v-chip>
                      </div>
                      <v-list-item-title
                        class="text--disabled text-caption text-md-body-2 font-weight-thin pt-1"
                        :style="$vuetify.breakpoint.mdAndUp ? null : 'white-space: normal !important'"
                      >
                        {{ line.baslik }}
                      </v-list-item-title>

                      <v-list-item-subtitle class="mt-1">
                        {{ line.total_price | currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-hover>
                <v-divider></v-divider>
              </div>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> Nakliye Ücreti </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.kargo_ucreti | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> Toplam Tutar </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.total_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right"> KDV </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.tax_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item>
                <v-list-item-content class="oneLine">
                  <v-list-item-title class="text-right">
                    <p class="pa-0 ma-0">Sipariş Toplam Tutarı</p>
                    <small class="text--disabled">(KDV Dahil)</small>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-right">
                    {{ detail.head.order_price | currency }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="detail.head.odeme_tipi === '2'" justify="center">
      <v-col md="10">
        <v-card>
          <v-row dense>
            <v-col cols="12" md="12">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-bold">
                        <span class="white--text">Banka Adı</span>
                      </td>
                      <td>{{ detail.banka.banka_adi }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        <span class="white--text">Hesap Alıcı Adı</span>
                      </td>
                      <td>{{ detail.banka.alici_adi }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        <span class="white--text">Şube Adı / Sube Kodu</span>
                      </td>
                      <td>{{ detail.banka.sube_adi }} / {{ detail.banka.sube_kodu }}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-bold">
                        <span class="white--text">IBAN</span>
                      </td>
                      <td>{{ detail.banka.iban }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col cols="12">
              <span class="text-body-2"
                >Siparişinizin işleme alınması için yukarıdaki banka hesabına havale/EFT işlemi yapabilirsiniz.</span
              >
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row class="px-4">
            <v-col cols="12">
              <span class="text-body-2"
                >Havale/EFT işlemini, sipariş verdikten itibaren 3 iş günü içinde tamamlamanız gerekmektedir.</span
              >
            </v-col>
          </v-row>
          <v-divider class="my-3" />
          <v-row class="px-4">
            <v-col cols="12">
              <span class="text-body-2"
                >Havale/EFT işlemi açıklama kısmına, aşağıdaki şekilde sipariş bilgilerinizi belirtmeniz gerekmektedir.
                "A" olarak belirtilen yere firma adınızı, "X" olarak belirtilen yere sipariş sonrasında verilecek dört
                haneli sipariş numaranızı eklemeyi unutmayınız.</span
              >
            </v-col>
          </v-row>
          <v-row class="px-4">
            <v-col cols="12">
              <v-alert type="warning" prominent dense text outlined>
                “A" Firmasının b2b.eksun.com.tr’den girdiği "X" nolu sipariş ödemesidir.
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-alert outlined color="warning" class="rounded-lg" height="100%">
          <v-card color="transparent" flat rounded="lg" height="100%">
            <v-card-title>
              <v-icon color="warning" class="mr-3">
                {{ icons.mdiMapMarker }}
              </v-icon>

              <div class="text-h6 warning--text">Teslimat Adresi</div>
              <v-spacer></v-spacer>
              <v-chip small color="warning" class="font-weight-light">
                {{ detail.head.teslimat_adresi.address_title }}
              </v-chip>
            </v-card-title>

            <v-card-text>
              <v-list class="pa-0" color="transparent">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="warning--text"> Alıcı Adı </v-list-item-subtitle>
                    <div style="color: white; font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>
                <v-list-item three-line class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="warning--text"> Adres </v-list-item-subtitle>
                    <div style="color: white; font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                      {{ detail.head.teslimat_adresi.city_txt }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>

                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="warning--text"> Alıcı Telefonu </v-list-item-subtitle>
                    <div style="color: white; font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.mobile }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-col>
      <v-col cols="12" md="5">
        <v-alert outlined color="secondary" height="100%">
          <v-card color="transparent" flat rounded="lg" height="100%">
            <v-card-title>
              <v-icon color="secondary" class="mr-3">
                {{ icons.mdiReceipt }}
              </v-icon>

              <div class="text-h6 ma-0 pa-0 secondary--text">Fatura Bilgileri</div>

              <v-spacer></v-spacer>
              <v-chip small color="secondary" class="font-weight-light"> Kurumsal </v-chip>
            </v-card-title>

            <v-card-text>
              <v-list class="pa-0" color="transparent">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="secondary--text"> Fatura Adı </v-list-item-subtitle>
                    <div style="color: white; font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.fatura_adresi.name }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>
                <v-list-item three-line class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="secondary--text"> Adres </v-list-item-subtitle>
                    <div style="color: white; font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.address }} {{ detail.head.teslimat_adresi.state }} /
                      {{ detail.head.teslimat_adresi.city_txt }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="grey my-3"></v-divider>
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-subtitle class="secondary--text"> Vergi Dairesi / Numarası </v-list-item-subtitle>
                    <div style="color: white; font-size: 14px; line-height: 1.5; font-weight: 100 !important">
                      {{ detail.head.teslimat_adresi.state }} / {{ detail.head.teslimat_adresi.mobile }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
          </v-card>
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="detail.head.buyer_note !== 'null'" justify="center">
      <v-col cols="12" md="10">
        <v-alert text outlined color="error"> Not : {{ detail.head.buyer_note }} </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import {
  mdiCalendar,
  mdiCheckBold,
  mdiContactlessPayment,
  mdiFormatListText,
  mdiMapMarker,
  mdiQrcodeScan,
  mdiReceipt,
  mdiTruckFast,
} from '@mdi/js'
import themeConfig from '@themeConfig'
import { onMounted, ref } from '@vue/composition-api'
import orderStoreModule from '@/views/order/orderStoreModule'
import store from '@/store'
import CounterTo from '@/components/CounterTo.vue'

export default {
  components: {
    CounterTo,
  },

  setup() {
    const PRODUCT_APP_STORE_MODULE_NAME = 'app-order'
    const { isDark } = useAppConfig()
    const detail = ref({})
    const { router } = useRouter()
    const status = ref(false)

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, orderStoreModule)
    }
    onMounted(() => {
      store.dispatch('app-order/fetchGetOrderStep4Data').then(response => {
        if (response.detail.error === 1) {
          router.push({ name: 'dashboard' })
        } else {
          detail.value = response.detail

          status.value = true
          store.dispatch('app-order/fetchBasketItemCount')
        }
      })
    })

    return {
      status,
      isDark,
      detail,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      appLogoDark: themeConfig.app.logoDark,
      textLogo: themeConfig.app.textLogo,
      textLogoDark: themeConfig.app.textLogoDark,
      icons: {
        mdiCalendar,
        mdiFormatListText,
        mdiReceipt,
        mdiMapMarker,
        mdiCheckBold,
        mdiQrcodeScan,
        mdiTruckFast,
        mdiContactlessPayment,
      },

      // Icons
    }
  },
}
</script>

<style></style>
